<template>
  <div class="products">
    <Breadcrumb :items="breadcrumb" :key="category.categoryId" />
    <v-container fluid>
      <ProductListGrid
        v-bind:parentCategoryId="category.categoryId"
        :key="category.categoryId"
        :category="category"
        @productsCount="updateCount"
        position="category_med"
      />
    </v-container>
  </div>
</template>
<script>
import ProductListGrid from "@/components/product/ProductListGrid";

import Breadcrumb from "@/components/navigation/Breadcrumb";
import categoryMixins from "~/mixins/category";
import get from "lodash/get";

export default {
  name: "CategoryMed",
  mixins: [categoryMixins],
  components: {
    ProductListGrid,
    Breadcrumb
  },
  //props: { category: { type: Object, required: true } },
  data() {
    return { count: -1 };
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    let text = this.category.name;
    if (this.breadcrumb.length > 0) {
      text = this.breadcrumb[this.breadcrumb.length - 2].text;
    }
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.secondAndThirdLevel.description", [
              this.category.name,
              text
            ])
          )
        }
      ]
    };
  }
};
</script>
